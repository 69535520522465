<template>
  <div class="add-transfer-stock">
    <KTCodePreview v-bind:title="title">
      <template v-slot:preview>
        <b-row class="pl-0">
          <b-col cols="6">
            <b-form-group class="required-control">
              <label>Tên chương trình: </label>
              <b-form-input
                size="sm"
                v-model="mainModel.name"
                aria-describedby="input-fullName-live-feedback"
                placeholder="Nhập tên chương trình"
              ></b-form-input>
            </b-form-group>
            <b-form-group>
              <label>Loại form đặt trước: </label>
              <b-form-select
                size="sm"
                v-model="mainModel.type"
                :options="preOrderFormType"
                value-field="id"
                text-field="name"
                class="select-style"
              >
                <template v-slot:first>
                  <b-form-select-option :value="null"
                    >-- Chọn loại form đặt trước --</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>
            <b-form-group>
              <label>Trạng thái: </label>
              <b-form-select
                size="sm"
                v-model="mainModel.status"
                :options="preOrderFormStatus"
                value-field="id"
                text-field="name"
                class="select-style"
              >
                <template v-slot:first>
                  <b-form-select-option :value="null"
                    >-- Chọn trạng thái --</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <hr class="hr-text font-weight-600" data-content="Sản phẩm" />

        <b-row>
          <b-form-group class="col-md-12 pr-0">
            <label>Tìm sản phẩm:</label>
            <b-col class="row pr-0">
              <b-col md="6" class="p-0">
                <Autosuggest
                  :model="searchProductFor"
                  :suggestions="optionProducts"
                  placeholder="tên, mã, mã vạch sản phẩm"
                  :limit="10"
                  @select="onSelectedProductFor"
                  @change="onInputChangeProductFor"
                  :disabled="false"
                  suggestionName="productName"
                />
              </b-col>
              <b-col md="2" class="p-0">
                <b-form-select
                  class="select-style border-left-0 rounded-left-0 rounded-right-0"
                  v-model="selectTypeSearchFor"
                  :options="listTypeSearch"
                  size="sm"
                  value-field="id"
                  text-field="name"
                  disabled-field="notEnabled"
                  @change="debounceInputFor"
                ></b-form-select>
              </b-col>
              <b-col md="2" class="p-0">
                <b-form-select
                  class="select-style border-left-0 rounded-left-0"
                  v-model="selectTypeOfProductFor"
                  :options="PRODUCT_TYPE_SEARCH"
                  size="sm"
                  value-field="id"
                  text-field="name"
                  disabled-field="notEnabled"
                  @change="debounceInputFor"
                ></b-form-select>
              </b-col>
            </b-col>
          </b-form-group>
        </b-row>
        <b-row>
          <b-col md="12">
            <div>
              <b-table
                bordered
                hover
                class="col-md-12"
                :fields="productFields"
                :items="mainModel.preOrderFormDetails"
                :per-page="10"
                :current-page="currentPageProductTable"
              >
                <template #cell(count)="row">
                  {{ ++row.index }}
                </template>
                <template
                  #cell(inStockQuantity)="row"
                  v-if="action !== 'create'"
                >
                  <span
                    v-b-tooltip.hover
                    title="Tồn thực tế"
                    class="text-primary pr-1"
                    >{{ convertPrice(row.item.totalQuantityInStock) }}</span
                  >
                  <span
                    v-if="row.item.totalQuantityInTransfer"
                    v-b-tooltip.hover
                    title="Tồn đang chuyển kho"
                    class="text-warning pl-1"
                    >+
                    {{ convertPrice(row.item.totalQuantityInTransfer) }}</span
                  >
                </template>
                <template #cell(productName)="row">
                  <span
                    v-b-tooltip.hover.right="'Bar code'"
                    class="font-weight-600"
                    >{{ row.item.barCode }}</span
                  ><br />
                  <span v-b-tooltip.hover.right="'Product Id'">{{
                    row.item.productId
                  }}</span
                  ><br />
                  <span v-b-tooltip.hover.right="'Product name'">{{
                    row.item.productName
                  }}</span>
                </template>
                <template #cell(limit)="row">
                  <b-form-input
                    size="sm"
                    v-model="row.item.limit"
                    aria-describedby="input-fullName-live-feedback"
                    placeholder="Nhập số lượng cho phép cọc"
                    v-mask="mask"
                  ></b-form-input>
                </template>
                <template #cell(minPrice)="row">
                  <b-form-input
                    size="sm"
                    v-model="row.item.minPrice"
                    aria-describedby="input-fullName-live-feedback"
                    placeholder="Nhập giá tối thiếu cọc"
                    v-mask="mask"
                  ></b-form-input>
                </template>
                <template #cell(current)="row">
                  <span
                    v-b-tooltip.hover.right="
                      'Số lượng đang đặt hàng thực tế(bao gồm đơn đã cọc)'
                    "
                    >{{ row.item.current ?? 0 }}</span
                  >
                  <span
                    v-if="row.item.preOrderSpamCount"
                    class="text-danger pl-2"
                    v-b-tooltip.hover.right="'Số lượng đơn spam tạm tính'"
                    >+ {{ row.item.preOrderSpamCount }}</span
                  >
                </template>
                <template #cell(currentDeposit)="row">
                  <span v-b-tooltip.hover.right="'Số lượng đã cọc hiện tại'">{{
                    row.item.currentDeposit ?? 0
                  }}</span>
                </template>
                <template v-slot:cell(actions)="row">
                  <i
                    class="flaticon2-rubbish-bin-delete-button text-danger"
                    role="button"
                    @click="
                      showDeleteAlert(
                        'Xoá sản phẩm',
                        `Bạn có chắc muốn xoá sản phẩm ${row.item.productName} `,
                        row.item,
                        confirmDeleteProduct,
                      )
                    "
                  ></i>
                </template>
              </b-table>
              <b-row>
                <b-col>
                  <p class="mt-3 text-dark font-weight-600">
                    Tổng số:
                    {{ mainModel.preOrderFormDetails?.length }}
                  </p>
                </b-col>
                <b-pagination
                  class="custom-pagination"
                  v-show="mainModel.preOrderFormDetails?.length > 10"
                  v-model="currentPageProductTable"
                  :total-rows="mainModel.preOrderFormDetails?.length"
                  :per-page="10"
                  align="right"
                >
                </b-pagination>
              </b-row>
            </div>
          </b-col>
          <b-col cols="6" md="4"></b-col>
        </b-row>
      </template>
      <template v-slot:foot>
        <b-button
          variant="primary"
          size="sm"
          class="font-weight-bolder mr-2 btn-size"
          v-if="action && !isBusy"
          @click="onSubmitDebounce()"
        >
          {{ actionName }}
        </b-button>
        <b-button
          v-if="isBusy"
          variant="primary"
          size="sm"
          class="font-weight-bolder mr-2 btn-size"
          disabled
        >
          <b-spinner small type="grow"></b-spinner>
          {{ actionName }}
        </b-button>
        <b-button
          variant="secondary"
          size="sm"
          class="btn-size font-weight-600"
          @click="goBack()"
          >Trở về</b-button
        >
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';

import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';

import localData from '@/utils/saveDataToLocal';
import {
  convertPrice,
  removeAccents,
  makeToastFaile,
  makeToastSuccess,
  unMaskPrice,
  currencyMask,
  handleError,
} from '@/utils/common';

import { formatDate } from '@/utils/date';

import { showDeleteAlert } from '@/utils/sweet-alert2';
import { STATUS_CODE, IS_ACTIVE, PRE_ORDER_FORM_TYPE } from '@/utils/enum';
import { IS_ACTIVE_NAME } from '@/utils/enum-name';

import debounce from 'lodash/debounce';
import cloneDeep from 'lodash/cloneDeep';
import {
  TIME_TRIGGER,
  PRODUCT_TYPE_SEARCH,
  PRODUCT_TYPES,
} from '@/utils/constants';

export default {
  data() {
    return {
      PRODUCT_TYPE_SEARCH,
      actionName: 'Lưu',
      cancelItem: null,
      action: null,
      mask: currencyMask,
      currentPageProductTable: 1,
      productFields: [
        {
          key: 'count',
          label: 'STT',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '2%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'productName',
          label: 'Tên sản phẩm',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '16%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'limit',
          label: 'SL cho phép',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '5%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'minPrice',
          label: 'Giá cọc',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '5%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'current',
          label: 'SL đơn',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '5%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'currentDeposit',
          label: 'SL đã cọc',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '5%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'inStockQuantity',
          label: 'Tồn',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '5%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'actions',
          label: '',
          tdClass: 'text-center',
          thStyle: { fontWeight: 600, color: '#181c32', width: '2%' },
        },
      ],
      selectTypeOfProductFor: 0,
      title: 'Thêm form đặt trước',
      filteredProducts: [],
      selectTypeSearchFor: 1,
      searchProductFor: '',
      filteredOptionsProduct: [],
      selectTypeSearch: 1,
      mainModel: {
        id: null,
        type: null,
        status: IS_ACTIVE.TRUE,
        preOrderFormDetails: [],
      },
      searchProduct: '',
      listTypeSearch: PRODUCT_TYPES,
      isBusy: false,
      preOrderFormType: [
        {
          id: PRE_ORDER_FORM_TYPE.PRE_ORDER,
          name: 'Đơn đặt trước',
        },
        {
          id: PRE_ORDER_FORM_TYPE.RECEIVE_INFORMATION,
          name: 'Đơn đăng kí nhận thông tin',
        },
      ],
      preOrderFormStatus: [
        {
          id: IS_ACTIVE.TRUE,
          name: IS_ACTIVE_NAME[IS_ACTIVE.TRUE],
        },
        {
          id: IS_ACTIVE.FALSE,
          name: IS_ACTIVE_NAME[IS_ACTIVE.FALSE],
        },
      ],
    };
  },
  components: {
    KTCodePreview,
    Autosuggest,
  },
  async created() {
    const { id, action } = this.$route.query;
    this.action = action;
    const promises = [];
    if (id) {
      this.mainModel.id = id;
      promises.push(this.getPreOrderFormInfo());
    }
    await Promise.all(promises);
    this.convertActionNameByAction();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.title }]);
  },
  computed: {
    optionProducts() {
      return [...cloneDeep(this.filteredProducts)];
    },
  },
  methods: {
    formatDate,
    convertPrice,
    showDeleteAlert,
    convertActionNameByAction() {
      const actionNameMap = {
        create: 'Lưu',
        update: 'Cập nhật',
      };
      this.actionName = actionNameMap[this.action] || 'Lưu';
    },
    goBack() {
      this.$router.go(-1);
    },
    async getPreOrderFormInfo() {
      try {
        const { data } = await ApiService.get(
          `pre-order-form/${this.mainModel.id}`,
        );
        const preOrderForm = data?.data;
        this.mainModel = preOrderForm;
      } catch (error) {
        handleError(error);
      }
    },
    confirmDeleteProduct(deleteItem) {
      this.mainModel.preOrderFormDetails =
        this.mainModel.preOrderFormDetails.filter(
          ({ productId }) => productId !== deleteItem.productId,
        );
    },
    createPreOrderForm(body) {
      return ApiService.post('pre-order-form', body);
    },
    updatePreOrderForm(body) {
      return ApiService.patch(`pre-order-form/${this.mainModel.id}`, body);
    },
    dispatchPreOrderFormRequest(action, body) {
      switch (action) {
        case 'create':
          return this.createPreOrderForm(body);
        case 'update':
          return this.updatePreOrderForm(body);
        default:
          throw new Error(`Action: '${action}' không hợp lệ!`);
      }
    },
    onSubmitDebounce: debounce(function () {
      this.onSubmit();
    }, TIME_TRIGGER),
    async onSubmit() {
      if (this.isBusy) return;
      if (!this.validateData()) return;
      this.isBusy = true;
      const body = {
        ...this.mainModel,
        preOrderFormDetails: this.mapPreOrderFormDetailsModel(
          this.mainModel.preOrderFormDetails,
        ),
      };
      try {
        const result = await this.dispatchPreOrderFormRequest(
          this.action,
          body,
        );
        if (result.data?.status === STATUS_CODE.SUCCESS) {
          makeToastSuccess(result.data.message);
          this.goBack();
        }
      } catch (error) {
        handleError(error);
      } finally {
        this.isBusy = false;
      }
    },
    mapPreOrderFormDetailsModel(preOrderFormDetails) {
      return preOrderFormDetails.map((preOrderFormDetail) => {
        const details = {
          productId: preOrderFormDetail.productId,
          limit: unMaskPrice(preOrderFormDetail.limit),
          minPrice: unMaskPrice(preOrderFormDetail.minPrice) || 0,
        };
        if (preOrderFormDetail.id) {
          details.id = preOrderFormDetail.id;
        }
        return details;
      });
    },
    debounceInputFor: debounce(function () {
      if (!this.isSearching) {
        let textSearch = this.searchProductFor;
        this.fetchProduct(textSearch);
      }
    }, TIME_TRIGGER),
    onInputChangeProductFor(text) {
      this.searchProductFor = text;
      this.debounceInputFor();
    },
    async fetchProduct(textSearch) {
      try {
        this.filteredProducts = this.filterProduct(textSearch);
        const searchProduct = textSearch ? textSearch.trim() : null;
        if (!this.filteredProducts?.length) {
          this.isSearching = true;

          if (!this.filteredProducts?.length) {
            const params = {
              searchProduct: searchProduct,
              typeSearch: this.selectTypeSearchFor,
              typeOfProduct: this.selectTypeOfProductFor,
            };
            this.filteredProducts = await this.searchProductByType(params);
          }
        }
      } catch (error) {
        handleError(error);
      } finally {
        this.isSearching = false;
      }
    },
    filterProduct(searchText) {
      return this.filteredProducts.filter((item) => {
        return (
          item.productName.toLowerCase().indexOf(searchText.toLowerCase()) >
            -1 ||
          removeAccents(item.productName).indexOf(removeAccents(searchText)) >
            -1
        );
      });
    },
    validateData() {
      if (!this.mainModel.name.trim()) {
        makeToastFaile('Nhập tên form đặt trước');
        return false;
      }
      if (!this.mainModel.type) {
        makeToastFaile('Chọn loại form đặt trước');
        return false;
      }
      return true;
    },
    async searchProductByType(params) {
      const {
        data: { data },
      } = await ApiService.query(`productSearch/search-by-type`, {
        params,
      });
      return data;
    },
    onSelectedProductFor(option) {
      this.searchProductFor = '';
      const index = this.mainModel.preOrderFormDetails.findIndex((product) => {
        return product.productId == option.item.productId;
      });
      if (index > -1) {
        return makeToastFaile(`${option.item.productName} đã tồn tại`);
      }
      let count = this.mainModel.preOrderFormDetails?.length + 1;
      this.mainModel.preOrderFormDetails.unshift({
        productId: option.item.productId,
        limit: unMaskPrice(option.item.limit) || 0,
        productName: option.item.productName,
        productCode: option.item.productCode,
        count: count,
      });
    },

    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
  },
};
</script>
<style scoped>
.font-weight-600 {
  font-weight: 600;
}

.btn-size {
  width: 90px;
}
</style>
